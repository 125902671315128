import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router , ActivatedRoute} from  '@angular/router';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ForgotPasswordService } from '../services/forgot-password.service';

declare var $:any;
declare var $:any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  submitted = false;
  btnlabel = "RESET MY PASSWORD";
  errormsg: any;
  successmsg:any;
  confirmpass:any;
  sentforgetpass= false;
  key:any;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private forgotPasswordService :ForgotPasswordService,
    private forgotpassword: FormBuilder
    ) {
      this.route.params.subscribe( params => console.log( this.key = params.key));
     }
  ngOnInit() {
    this.sentforgetpass= false;
    this.errormsg = "";
    this.successmsg = "";
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

    $('body').addClass("darkBg");
     this.headerService.removejscssfile("custom_js.js", "js");
     this.headerService.loadScript('../assets/include/js/custom_js.js'); 
     setTimeout(() => {
      $('.mainFooter').css({"display":"none"});
    }, 500);

     this.forgotpasswordForm = this.forgotpassword.group({
      email: ['', [Validators.required]],
  });

    }
    get f() { return this.forgotpasswordForm.controls; }
   
    onSubmit() {
      this.submitted = true;
      
      // stop here if form is invalid
      if (this.forgotpasswordForm.invalid) {
          return;
      }
      this.errormsg = "";
      this.btnlabel = "PLEASE WAIT..";

      this.forgotPasswordService.forgotpassword(this.forgotpasswordForm.value.email).subscribe( logindata => {
       // console.log("login successfully -------- "+JSON.stringify(logindata['data']));
       if(logindata['replyStatus']=="error"){
          this.successmsg = ""; 
          this.errormsg = logindata['replyMessage'];
        }
        if(logindata['replyStatus']=="success"){
          this.sentforgetpass= true;
        //  localStorage.setItem('userdata',JSON.stringify(logindata['data']));
          this.errormsg = "";
          this.successmsg = logindata['replyMessage'];
          setTimeout(() => {
//            this.router.navigate(['login']);
          }, 3000);
        }
        this.btnlabel = "RESET MY PASSWORD";

       },
       error => {
         alert("Server Error, Please refresh page again.");
        });  
  }   

  dashboard(){
    this.router.navigate(['dashboard']);
  }
}
