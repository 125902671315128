import { Component, OnInit } from '@angular/core';
declare var $:any;
import { Router , ActivatedRoute} from  '@angular/router';
import { HeaderService } from '../services/header.service';
import { BrandStoryService } from '../services/brand-story.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  typelist = [];
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private brandStoryService:BrandStoryService
    ) { }
 
  ngOnInit() {

    // if(window.location.pathname=="/brand-story"){
    //   $('.homeBtn').addClass('homeActiveBtn');
    // }else{
    //   $('.homeBtn').removeClass('homeActiveBtn');
    // } 
    this.getBrandStoryData();
  }
   
  getBrandStoryData(){
    this.brandStoryService.getBrandStoryData().subscribe( data => {
        this.typelist = data['data'];
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
  hidemenulist(url:any){
    localStorage.setItem('url',url);
   // alert(window.location.pathname);
    // if(url=="brand-story"){
    //   $('.homeBtn').addClass('homeActiveBtn');
    // }else{
    //   $('.homeBtn').removeClass('homeActiveBtn');
    // }     
   // this.router.navigate([link]);
   $('.menuBtn1').removeClass('activeMenuBtn');
   $('.menuWrap').removeClass("activeMenu");
   if($("body").hasClass( "scrollHidden" )){
    $("html, body").removeClass('scrollHidden');
  }
  setTimeout(() => {
//    alert("shi h");
    if(!$("html").hasClass("fp-enabled")){
      $(".fp-right").css({"display":"none"});
    }
      
  }, 100);
     this.headerService.removejscssfile("custom_js.js", "js");
     this.headerService.loadScript('../assets/include/js/custom_js.js'); 
    }
}
