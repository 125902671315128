import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { PhilosophyDetailService } from '../services/philosophy-detail.service';
import { Router , ActivatedRoute} from  '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

declare var $:any;

@Component({
  selector: 'app-philosophy-detail',
  templateUrl: './philosophy-detail.component.html',
  styleUrls: ['./philosophy-detail.component.css']
})
export class PhilosophyDetailComponent implements OnInit {
  errormsg: any;
  successmsg:any;
  philosophyDetail = [];
  philosophyType={
    image : "",
    description:"",
    name : ""
  };
  section1={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };
  section2={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };

  section3={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };

  section4={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };

  section5={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };

  section6={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };

  section7={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };

  section8={
    title : "",
    description : "",
    image : "",
    button_type : ""
  };
  lovefirstpara:any;
  lovesecondpara:any;

  lovestatus  = false;
  craftstatus = false;
  artstatus   = false;

  craftsec1:any;
  craftsec2:any;
  craftsec3:any;
  craftsec4:any;
  craftsec5:any;
  craftsec6:any;
  craftsec7:any;
  craftsec8:any;
  craftsec9:any;
  craftsec10:any;
  craftsec11:any;
  craftsec12:any;
  craftsec13:any;
  craftsec14:any;

  artsec1:any;
  artsec2:any;
  artsec3:any;
  artsec4:any;
  artsec5:any;
  artsec6:any;
  artsec7:any;
  artsec8:any;
  artsec9:any;

  type:any;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private philosophyDetailService:PhilosophyDetailService
    ) { 
      this.route.params.subscribe( 
        params => {
        this.type = params.cat
        this.getPhilosophyDetail();
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      });

    }

  ngOnInit() {
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    if(!$("html").hasClass("fp-enabled")){
      $(".fp-right").css({"display":"none"});
    }
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    this.headerService.removejscssfile("custom_js.js", "js");
    this.headerService.loadScript('../assets/include/js/custom_js.js');   

    this.getPhilosophyDetail();
  }
 
  getPhilosophyDetail(){
    this.spinner.show();
    this.errormsg = ""; 
    this.successmsg = "";
    let params = "methodName=get_philosophy_detail&type="+this.type;
    this.philosophyDetailService.getPhilosophyDetail(params).subscribe( data => {
        this.philosophyType = data['data']['philosophy_type'];
        if(this.type=="love"){
          this.lovestatus  = true;
          this.craftstatus = false;
          this.artstatus   = false;
        
          this.section1 = data['data']['philosophy']['0'];
          let firsttitle = this.section2.description;
          let res = firsttitle.split("######");
          this.lovefirstpara = res[0];
          this.lovesecondpara = res[1];
          this.section2 = data['data']['philosophy']['1'];
          this.section3 = data['data']['philosophy']['2'];
          this.section4 = data['data']['philosophy']['3'];
          this.section5 = data['data']['philosophy']['4'];
          this.section6 = data['data']['philosophy']['5'];
          this.section7 = data['data']['philosophy']['6'];
          this.section8 = data['data']['philosophy']['7'];
        }
        if(this.type=="craft"){
          this.lovestatus  = false;
          this.craftstatus = true;
          this.artstatus   = false;
          this.craftsec1 = data['data']['philosophy']['0'];
          this.craftsec2 = data['data']['philosophy']['1'];
          this.craftsec3 = data['data']['philosophy']['2'];
          this.craftsec4 = data['data']['philosophy']['3'];
          this.craftsec5 = data['data']['philosophy']['4'];
          this.craftsec6 = data['data']['philosophy']['5'];
          this.craftsec7 = data['data']['philosophy']['6'];
          this.craftsec8 = data['data']['philosophy']['7'];
          this.craftsec9 = data['data']['philosophy']['8'];
          this.craftsec10 = data['data']['philosophy']['9'];
          this.craftsec11 = data['data']['philosophy']['10'];
          this.craftsec12 = data['data']['philosophy']['11'];
          this.craftsec13 = data['data']['philosophy']['12'];
          this.craftsec14 = data['data']['philosophy']['13'];
        }
        if(this.type=="art"){
          this.lovestatus  = false;
          this.craftstatus = false;
          this.artstatus   = true;
          this.artsec1 = data['data']['philosophy']['0'];
          this.artsec2 = data['data']['philosophy']['1'];
          this.artsec3 = data['data']['philosophy']['2'];
          this.artsec4 = data['data']['philosophy']['3'];
          this.artsec5 = data['data']['philosophy']['4'];
          this.artsec6 = data['data']['philosophy']['5'];
          this.artsec7 = data['data']['philosophy']['6'];
          this.artsec8 = data['data']['philosophy']['7'];
          this.artsec9 = data['data']['philosophy']['8'];
        }

        this.spinner.hide();
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
  
}
