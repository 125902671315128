import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PhilosophyComponent } from './philosophy/philosophy.component';
import { PhilosophyDetailComponent } from './philosophy-detail/philosophy-detail.component';
import { BrandStoryComponent } from './brand-story/brand-story.component';
import { BrandStoryDetailComponent } from './brand-story-detail/brand-story-detail.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { PhilosophyBespokeComponent } from './philosophy-bespoke/philosophy-bespoke.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccessRequestComponent } from './access-request/access-request.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { PagesComponent } from './pages/pages.component';
import { ActivationComponent } from './activation/activation.component';
import { CreatePasswordComponent } from './create-password/create-password.component';

const routes: Routes = [ 
  { path: '', component: HomeComponent },
  { path: 'philosophy', component: PhilosophyComponent },
  { path: 'philosophy/:cat', component: PhilosophyDetailComponent },
  { path: 'brand-story', component: BrandStoryComponent },
  { path: 'brand-story/:cat', component: BrandStoryDetailComponent },
  { path: 'enquiry', component: EnquiryComponent },
  { path: 'philosophy-bespoke', component: PhilosophyBespokeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'access-request', component: AccessRequestComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-username', component: ForgotUsernameComponent },
  { path: 'page/:slug', component: PagesComponent },
  { path: 'activation', component: ActivationComponent },
  { path: 'account/:key', component: CreatePasswordComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
