import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HeaderService } from '../services/header.service';

declare var $:any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  BillingForm: FormGroup;
  ShippingForm: FormGroup;
  AccountForm: FormGroup;
  shippingaddr = false;
  profile = {
    first_name : "",
    last_name : "",
    main_last_name:"",
    main_first_name : "",
    main_email : "",
    email : "",
    mobileno : "",
    company_name: "",
    city : "",
    state_id : "",
    country_id : "",
    address : "",
    salutation: "",
    sfirst_name : "",
    slast_name : "",
    semail : "",
    smobileno : "",
    scompany_name: "",
    scity : "",
    sstate_id : "",
    scountry_id : "",
    saddress : "",
    szip_code : "",
    zip_code : "",
    title : ""
  };
  bsubmitted = false;
  ssubmitted = false;
  asubmitted = false;

  milestonedata = [];
  user_id :any;
  countrylist =[];
  errormsg: any;
  successmsg:any;
  paymentlist = [];
  statelist = [];
  sstatelist = [];

  billingbtntext:any;
  shippingbtntext:any;
  accountbtntext:any;
  billingbtnclass:any;
  shippingbtnclass:any;
  accountbtnclass:any;

  shortdescription:any;
  fileUrl:any;
  user_data: any;
  constructor(private route: ActivatedRoute,private router: Router
    ,private dashboardService:DashboardService,
    private billingAddress: FormBuilder,
    public headerService:HeaderService,
    private http: HttpClient,
    private account: FormBuilder,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    this.billingbtntext = "SAVE ADDRESS";
    this.shippingbtntext = "SAVE ADDRESS";
    this.accountbtntext = "Save Details";
    this.billingbtnclass = "blackBtn";
    this.shippingbtnclass = "blackBtn";
    this.accountbtnclass = "blackBtn";

    this.user_id = "";
    this.shortdescription = "";
    this.errormsg = "";
    this.successmsg = "";

    console.log("this is session data = "+localStorage.getItem('userdata'));
    //localStorage.removeItem('userdata');
    if(!localStorage.getItem('userdata')){
      this.router.navigate(['login']);
    }else{
      let userdata = [];
      userdata            = JSON.parse(localStorage.getItem('userdata'));
      //console.log(userdata['first_name']+" dddd");
      this.user_id              = userdata['id'];
      this.profile.salutation   = userdata['salutation'];
      this.profile.main_first_name   = userdata['first_name'];
      this.profile.main_last_name    = userdata['last_name'];
      this.profile.main_email        = userdata['email'];

      if(userdata['billingAddress']!=null){
        this.profile.first_name   = userdata['billingAddress']['first_name'];
        this.profile.last_name    = userdata['billingAddress']['last_name'];
        this.profile.email        = userdata['billingAddress']['email'];
        this.profile.mobileno     = userdata['billingAddress']['mobileno'];
//          this.profile.salutation   = userdata['salutation'];
        this.profile.company_name = userdata['billingAddress']['company_name'];
        this.profile.city         = userdata['billingAddress']['city'];
        this.profile.country_id  = userdata['billingAddress']['country_name'];
        this.profile.state_id    = userdata['billingAddress']['state_name'];
        this.profile.zip_code     = userdata['billingAddress']['zip_code'];
        this.profile.address      = userdata['billingAddress']['address'];
      }

      if(userdata['shippingAddress']!=null){
        this.shippingaddr          = true;
        this.profile.sfirst_name   = userdata['shippingAddress']['first_name'];
        this.profile.slast_name    = userdata['shippingAddress']['last_name'];
        this.profile.semail        = userdata['shippingAddress']['email'];
        this.profile.smobileno     = userdata['shippingAddress']['mobileno'];
//          this.profile.salutation   = userdata['salutation'];
        this.profile.company_name = userdata['shippingAddress']['company_name'];
        this.profile.scity         = userdata['shippingAddress']['city'];
        this.profile.scountry_id = userdata['shippingAddress']['country_name'];
        this.profile.sstate_id   = userdata['shippingAddress']['state_name'];
        this.profile.szip_code     = userdata['shippingAddress']['zip_code'];
        this.profile.saddress      = userdata['shippingAddress']['address'];
      }

    }

    this.BillingForm = this.billingAddress.group({
      company_name: [this.profile.company_name],
      first_name: [this.profile.first_name, [Validators.required]],
      last_name: [this.profile.last_name, [Validators.required]],
      city: [this.profile.city, [Validators.required]],
      address: [this.profile.address, [Validators.required]],
      state_id: [this.profile.state_id, [Validators.required]],
      country_id: [this.profile.country_id, [Validators.required]],
      zip_code: [this.profile.zip_code, [Validators.required]],
      email: [this.profile.email, [Validators.required]],
      mobileno: [this.profile.mobileno, [Validators.required]]
    });
    //console.log(this.shippingaddr);
    if(this.shippingaddr==true){
      this.ShippingForm = this.billingAddress.group({
      same_as_billing: ['No'],
      company_name: [this.profile.company_name],
      first_name: [this.profile.sfirst_name, [Validators.required]],
      last_name: [this.profile.slast_name, [Validators.required]],
      city: [this.profile.scity, [Validators.required]],
      address: [this.profile.saddress, [Validators.required]],
      state_id: [this.profile.sstate_id, [Validators.required]],
      country_id: [this.profile.scountry_id, [Validators.required]],
      zip_code: [this.profile.szip_code, [Validators.required]],
      email: [this.profile.semail, [Validators.required]],
      mobileno: [this.profile.smobileno, [Validators.required]]
    });
  }else{
    this.ShippingForm = this.billingAddress.group({
      same_as_billing: ['No'],
      company_name: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      email: ['', [Validators.required]],
      mobileno: ['', [Validators.required]]
    });
  }
 


    this.AccountForm = this.billingAddress.group({
      first_name: [this.profile.main_first_name, [Validators.required]],
      last_name: [this.profile.main_last_name, [Validators.required]],
      email: [this.profile.main_email, [Validators.required]],
      npassword: [''],
      cpassword: [''],
      copassword: ['']
    });


    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    $('body').addClass("whiteBg");
    this.headerService.removejscssfile("bootstrap.min.js", "js");
    this.headerService.loadScript('../assets/include/js/bootstrap.min.js');

    this.headerService.removejscssfile("bootstrap-select.min.js", "js");
    this.headerService.loadScript('../assets/include/js/bootstrap-select.min.js');

    this.getMilestone();
    this.getState(this.profile.country_id);

    if(this.profile.scountry_id==""){
      this.sstatelist = [];
    }
  }
  returnfileurl(url:any){
    alert(url);
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }
  getcountryid(id:any){

    //    this.country_id = id;
    this.getState(id);
  }

  getscountryid(id:any){

    //    this.country_id = id;
    this.getsState(id);
  }

  get f() { return this.BillingForm.controls; }
  get s() { return this.ShippingForm.controls; }
  get ac() { return this.AccountForm.controls; }


  updateBillingAddress(){
    this.bsubmitted = true;

    // stop here if form is invalid
    console.log(this.BillingForm.invalid);
    if (this.BillingForm.invalid) {
        return;
    }
    this.errormsg = "";
    let params = "methodName=editAddress&user_id="+this.user_id+"&company_name="+this.BillingForm.value.company_name+"&first_name="+this.BillingForm.value.first_name+"&last_name="+this.BillingForm.value.last_name+"&email="+this.BillingForm.value.email+"&mobileno="+this.BillingForm.value.mobileno+"&city="+this.BillingForm.value.city+"&state_id="+this.BillingForm.value.state_id+"&country_id="+this.BillingForm.value.country_id+"&zip_code="+this.BillingForm.value.zip_code+"&address="+this.BillingForm.value.address+"&form_type=Billing";
    this.dashboardService.updateBillingAddress(params).subscribe( data => {
     // console.log("Access Request sent successfully -------- "+JSON.stringify(logindata['data']));
     if(data['replyStatus']=="error"){
        this.successmsg = "";
        this.errormsg = data['replyMessage'];
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        }, 100);
      }
      if(data['replyStatus']=="success"){
        this.errormsg = "";
        this.billingbtntext = "YOUR ADDRESS HAS BEEN SAVED";
        this.successmsg = data['replyMessage'];
        this.billingbtnclass = "whiteBtn";
    
        let userdata = data['data']; 
        if(userdata['billingAddress']!=null){
          this.profile.first_name   = userdata['billingAddress']['first_name'];
          this.profile.last_name    = userdata['billingAddress']['last_name'];
          this.profile.email        = userdata['billingAddress']['email'];
          this.profile.mobileno     = userdata['billingAddress']['mobileno'];
//          this.profile.salutation   = userdata['salutation'];
          this.profile.company_name = userdata['billingAddress']['company_name'];
          this.profile.city         = userdata['billingAddress']['city'];
          this.profile.country_id  = userdata['billingAddress']['country_name'];
          this.profile.state_id    = userdata['billingAddress']['state_name'];
          this.profile.zip_code     = userdata['billingAddress']['zip_code'];
          this.profile.address      = userdata['billingAddress']['address'];
        }
  
        if(userdata['shippingAddress']!=null){
          this.shippingaddr          = true;
          this.profile.sfirst_name   = userdata['shippingAddress']['first_name'];
          this.profile.slast_name    = userdata['shippingAddress']['last_name'];
          this.profile.semail        = userdata['shippingAddress']['email'];
          this.profile.smobileno     = userdata['shippingAddress']['mobileno'];
//          this.profile.salutation   = userdata['salutation'];
          this.profile.company_name = userdata['shippingAddress']['company_name'];
          this.profile.scity         = userdata['shippingAddress']['city'];
          this.profile.scountry_id = userdata['shippingAddress']['country_name'];
          this.profile.sstate_id   = userdata['shippingAddress']['state_name'];
          this.profile.szip_code     = userdata['shippingAddress']['zip_code'];
          this.profile.saddress      = userdata['shippingAddress']['address'];
        }

        localStorage.setItem('userdata',JSON.stringify(data['data']));
      }
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });      
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }





  updateShippingAddress(){
    this.ssubmitted = true;

    // stop here if form is invalid
    console.log(this.ShippingForm.invalid);
    if (this.ShippingForm.invalid) {
        return;
    }
    this.errormsg = "";
    let params = "methodName=editAddress&user_id="+this.user_id+"&same_as_billing="+this.ShippingForm.value.same_as_billing+"&company_name="+this.ShippingForm.value.company_name+"&first_name="+this.ShippingForm.value.first_name+"&last_name="+this.ShippingForm.value.last_name+"&email="+this.ShippingForm.value.email+"&mobileno="+this.ShippingForm.value.mobileno+"&city="+this.ShippingForm.value.city+"&state_id="+this.ShippingForm.value.state_id+"&country_id="+this.ShippingForm.value.country_id+"&zip_code="+this.ShippingForm.value.zip_code+"&address="+this.ShippingForm.value.address+"&form_type=Shipping";
    this.dashboardService.updateBillingAddress(params).subscribe( data => {
     // console.log("Access Request sent successfully -------- "+JSON.stringify(logindata['data']));
     if(data['replyStatus']=="error"){
        this.successmsg = "";
        this.errormsg = data['replyMessage'];
        setTimeout(() => {
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        }, 100);
      }
      if(data['replyStatus']=="success"){
        this.errormsg = "";
        this.shippingbtntext = "YOUR ADDRESS HAS BEEN SAVED";
        this.successmsg = data['replyMessage'];
        this.shippingbtnclass = "whiteBtn";
    
        let userdata = data['data']; 
        this.profile.first_name   = userdata['first_name'];
        this.profile.last_name    = userdata['last_name'];
        this.profile.email        = userdata['email'];
        this.profile.mobileno     = userdata['mobileno'];
        this.profile.salutation   = userdata['salutation'];
        this.profile.company_name = userdata['company_name'];
        if(userdata['billingAddress']!=null){
          this.profile.city         = userdata['billingAddress']['city'];
          this.profile.country_id  = userdata['billingAddress']['country_name'];
          this.profile.state_id    = userdata['billingAddress']['state_name'];
          this.profile.zip_code     = userdata['billingAddress']['zip_code'];
          this.profile.address      = userdata['billingAddress']['address'];
        }
  
        if(userdata['shippingAddress']!=null){
          this.shippingaddr          = true;
          this.profile.scity         = userdata['shippingAddress']['city'];
          this.profile.scountry_id = userdata['shippingAddress']['country_name'];
          this.profile.sstate_id   = userdata['shippingAddress']['state_name'];
          this.profile.szip_code     = userdata['shippingAddress']['zip_code'];
          this.profile.saddress      = userdata['shippingAddress']['address'];
        }
        
        localStorage.setItem('userdata',JSON.stringify(data['data']));
      }
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });      
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }


  sameasbilling(options:any){
    if(options=="Yes"){
      this.getState(this.profile.country_id);

      this.ShippingForm = this.billingAddress.group({
        same_as_billing: ['Yes'],
        company_name: [this.profile.company_name],
        first_name: [this.profile.main_first_name],
        last_name: [this.profile.main_last_name],
        city: [this.profile.city],
        address: [this.profile.address],
        state_id: [this.profile.state_id],
        country_id: [this.profile.country_id],
        zip_code: [this.profile.zip_code],
        email: [this.profile.main_email],
        mobileno: [this.profile.mobileno]
      });
    }
    if(options=="No"){
      this.ShippingForm = this.billingAddress.group({
        same_as_billing: ['No'],
        company_name: [''],
        first_name: [''],
        last_name: [''],
        city: [''],
        address: [''],
        state_id: [''],
        country_id: [''],
        zip_code: [''],
        email: [''],
        mobileno: ['']
      });
    }

  }


  updateAccount(){
    this.asubmitted = true;

    // stop here if form is invalid
    console.log(this.AccountForm.invalid);
    if (this.AccountForm.invalid) {
        return;
    }
    this.errormsg = "";
    let params = "methodName=editProfile&user_id="+this.user_id+"&first_name="+this.AccountForm.value.first_name+"&last_name="+this.AccountForm.value.last_name+"&email="+this.AccountForm.value.email+"&npassword="+this.AccountForm.value.npassword+"&cpassword="+this.AccountForm.value.cpassword+"&copassword="+this.AccountForm.value.copassword;
    this.dashboardService.updateAccount(params).subscribe( data => {
     // console.log("Access Request sent successfully -------- "+JSON.stringify(logindata['data']));
     if(data['replyStatus']=="error"){
        this.successmsg = "";
        this.errormsg = data['replyMessage'];
      }
      if(data['replyStatus']=="success"){
        this.errormsg = "";
        this.accountbtntext = "Details Saved";
        this.successmsg = data['replyMessage'];
        this.accountbtnclass = "whiteBtn";
    
        let userdata = data['data']; 
        this.profile.first_name   = userdata['first_name'];
        this.profile.last_name    = userdata['last_name'];
        this.profile.email        = userdata['email'];
        this.AccountForm = this.billingAddress.group({
          first_name: [this.profile.first_name, [Validators.required]],
          last_name: [this.profile.last_name, [Validators.required]],
          email: [this.profile.email, [Validators.required]],
          npassword: [''],
          cpassword: [''],
          copassword: ['']
        });
        localStorage.setItem('userdata',JSON.stringify(data['data']));
      }
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });      
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
  getpaymentlist(){
    this.errormsg = "";
    this.successmsg = "";
    this.errormsg = "";
    this.dashboardService.getpaymentData(this.user_id).subscribe( paymentdata => {
    //  console.log("Access Request sent successfully -------- "+JSON.stringify(paymentdata['data']));
     if(paymentdata['replyStatus']=="error"){
        this.successmsg = "";
        this.errormsg = paymentdata['replyMessage'];
      }
      if(paymentdata['replyStatus']=="success"){
        this.paymentlist = paymentdata['data'];
        this.errormsg = "";
        this.successmsg = paymentdata['replyMessage'];
      }
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }


  getMilestone(){
    this.errormsg = "";
    this.successmsg = "";
    $('.mainFooter').css({"display":"none"});

    let params = "methodName=get_milestone&user_id="+this.user_id;
    this.dashboardService.getMilestone(params).subscribe( data => {
        this.milestonedata = data['data'];
        this.user_data = data['user_data'];
        this.milestonedata.forEach(element => {
          if(element.reached){
            this.shortdescription = element.short_description;
          }
        });
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }

  getCountry(){
    this.errormsg = "";
    this.successmsg = "";
    this.dashboardService.getCountry().subscribe( data => {
    //  console.log("Access Request sent successfully -------- "+JSON.stringify(paymentdata['data']));
        this.countrylist = data['data'];
        setTimeout(() => {
          this.headerService.removejscssfile("bootstrap-select.min.js", "js");
          this.headerService.loadScript('../assets/include/js/bootstrap-select.min.js'); 
          $('.selectSty').selectpicker();
        }, 100);    
      },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }

  hidemsg(){
    this.errormsg = "";
    this.successmsg = "";
  }
  getsState(id:any){

    this.dashboardService.getState(id).subscribe( data => {
    //  console.log("Access Request sent successfully -------- "+JSON.stringify(paymentdata['data']));
        this.sstatelist = data['data'];
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
  getState(id:any){

    this.dashboardService.getState(id).subscribe( data => {
    //  console.log("Access Request sent successfully -------- "+JSON.stringify(paymentdata['data']));
        this.statelist = data['data'];
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }

  formatDate(dateString: any) {
		//      let now = new Date(dateString * 1000);
    let now = new Date(dateString);
    let gsmonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
		let dd = now.getDate();
		let mm = now.getMonth();
		let yyyy = now.getFullYear();
		let hh = now.getHours();
		let m = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes();
		let ss = (now.getSeconds() < 10 ? '0' : '') + now.getSeconds()
		let ampm = hh >= 12 ? 'PM' : 'AM';
		hh = hh % 12;
		hh = hh ? hh : 12;
		let datecheck = gsmonthNames[mm]+" "+dd + ","+ yyyy;
		return datecheck;
		//  console.log("date checking------------------"+datecheck);
	}

  logout(){
    localStorage.removeItem('userdata');
    this.router.navigate(['/']);
  }
}
