import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router , ActivatedRoute} from  '@angular/router';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { CreatePasswordService } from '../services/create-password.service';

declare var $:any;

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  createpassForm: FormGroup;
  submitted = false;
  errormsg: any;
  successmsg:any;
  confirmpass:any;
  congtrs = false;
  key:any;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private createPasswordService :CreatePasswordService,
    private createpass: FormBuilder
    ) {
      this.route.params.subscribe( params => console.log( this.key = params.key));
     }

    ngOnInit() {
      this.errormsg = "";
      this.successmsg = "";
      if(localStorage.getItem('userdata')){
        this.router.navigate(['dashboard']);
      }
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      $('body').removeAttr("class");
      $('body').removeAttr("style");
      $('html').removeAttr("style");
      $('html').removeAttr("class");
      $('body').addClass("darkBg");
       this.headerService.removejscssfile("custom_js.js", "js");
       this.headerService.loadScript('../assets/include/js/custom_js.js'); 
      
       this.createpassForm = this.createpass.group({
        npassword: ['', [Validators.required]],
        cpassword: ['', [Validators.required]]
    });

      }
      get f() { return this.createpassForm.controls; }
      onSubmit() {
        this.submitted = true;
        
        // stop here if form is invalid
        if (this.createpassForm.invalid) {
            return;
        }
        this.errormsg = "";
        this.createPasswordService.changePassword(this.key,this.createpassForm.value.npassword,this.createpassForm.value.cpassword).subscribe( logindata => {
         // console.log("login successfully -------- "+JSON.stringify(logindata['data']));
         if(logindata['replyStatus']=="error"){
            this.successmsg = ""; 
           // this.congtrs = true;

            if(logindata['replyMessage']=="The cpassword and npassword must match.<br>"){
              this.errormsg = "Passwords do not match.";
            }else{
              this.errormsg = logindata['replyMessage'];
            }
          }
          if(logindata['replyStatus']=="success"){
            this.congtrs = true;
          //  localStorage.setItem('userdata',JSON.stringify(logindata['data']));
            this.errormsg = "";
            this.successmsg = logindata['replyMessage'];
            // setTimeout(() => {
            //   this.router.navigate(['login']);
            // }, 3000);
          }
         },
         error => {
           alert("Server Error, Please refresh page again.");
          });  
    }
 
}
