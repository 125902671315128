import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router , ActivatedRoute} from  '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from '@angular/common/http';
import { HomeService } from '../services/home.service';
import { PagesService } from '../services/pages.service';

import { DeviceDetectorService } from 'ngx-device-detector';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
 // encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
  @ViewChildren('sectionRef') sectionRef: QueryList<any>;
  config: any;
  fullpage_api: any;
  sections = [1];
  deviceInfo : any;
  isDesktopDevice : any;
  isMobile :any;
  errormsg: any;
  successmsg:any;
  bannerlist = [];
  cookiedata:any;
  constructor(
    public headerService:HeaderService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private pagesService:PagesService,
    private homeService: HomeService,
    public deviceService: DeviceDetectorService
) { 
    this.epicFunction();
    // for more details on config options please visit fullPage.js docs
    // this is just an example => for more details on config please visit fullPage.js docs
    this.config = {
      licenseKey: 'YOUR LICENSE KEY HERE',
      verticalCentered: true,
      anchors: ['MARBLE', 'BESPOKE', 'LOVE', 'FOOTER'],
      menu: '#menu',
      sectionsColor: ['#FFF', '#000', '#FFF', '#000'],
      navigation: true,
      navigationPosition: 'right',
  };
    // console.log('PLATFORM_ID' , PLATFORM_ID);
  } 


  epicFunction() {
    console.log('hello Home component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
     console.log("🚀 ~ file: home.component.ts:59 ~ HomeComponent ~ epicFunction ~ deviceInfo:", this.deviceInfo)
     this.isMobile = this.deviceService.isMobile();
     console.log("🚀 ~ file: home.component.ts:61 ~ HomeComponent ~ epicFunction ~ isMobile:", this.isMobile)
    //  this.isTablet = this.deviceService.isTablet();
    //  console.log("🚀 ~ file: home.component.ts:61 ~ HomeComponent ~ epicFunction ~ isTablet:", isTablet)
      this.isDesktopDevice = this.deviceService.isDesktop();
     console.log("🚀 ~ file: home.component.ts:65 ~ HomeComponent ~ epicFunction ~ isDesktopDevice:", this.isDesktopDevice)
      // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      //console.log(isTablet);  // returns if the device us a tablet (iPad etc)
     //console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }


  ngOnInit() {
     this.headerService.loadmenujs();
     $('.homeBtn').removeClass('homeActiveBtn');
     $('.menuBtn1').removeClass('activeMenuBtn');
     $(".menuWrap").removeClass('activeMenu');
     $(".brandmenuWrap").removeClass('activeMenu');

     $('body').removeClass('whiteBg');
     $("body").removeClass('darkBg');
      this.getBanners();
      this.getPageData();
      localStorage.removeItem("bannerloaded");
     localStorage.setItem("bannerloaded",'true');

    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    setTimeout(() => {
    }, 1000);
    // this.headerService.removejscssfile("fullpage.min.js", "js");
    // this.headerService.loadScript('../assets/include/js/fullpage.min.js'); 
    // this.headerService.removejscssfile("custom_js.js", "js");
    // this.headerService.loadScript('../assets/include/js/custom_js.js'); 
    //this.headerService.loadScripts();
  }
  ngAfterViewInit() {
    this.sectionRef.changes.subscribe(() => {
      this.ngForRendred();
    });
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  ngForRendred() {
    console.log('ngFor dom render is done');
    this.fullpage_api.build();
  }
  // hidemenulist(){
  //   // this.router.navigate([link]);
  //   $('.menuBtn1').removeClass('activeMenuBtn');
  //   $('.menuWrap').removeClass("activeMenu");
  //   if($("body").hasClass( "scrollHidden" )){
  //    $("html, body").removeClass('scrollHidden');
  //  }
  //  setTimeout(() => {
  //    //alert("shi h");
  //    if(!$("html").hasClass("fp-enabled")){
  //      $(".fp-right").css({"display":"block"});
  //    }
       
  //  }, 100);
  //    }
     hidemenulist(url:any){
      localStorage.setItem('url',url);
     // alert(window.location.pathname);
      // if(url=="brand-story"){
      //   $('.homeBtn').addClass('homeActiveBtn');
      // }else{
      //   $('.homeBtn').removeClass('homeActiveBtn');
      // }     
     // this.router.navigate([link]);
     $('.menuBtn1').removeClass('activeMenuBtn');
     $('.menuWrap').removeClass("activeMenu");
     if($("body").hasClass( "scrollHidden" )){
      $("html, body").removeClass('scrollHidden');
    }
    setTimeout(() => {
  //    alert("shi h");
      if(!$("html").hasClass("fp-enabled")){
        $(".fp-right").remove();
      }
        
    }, 100);
      //  this.headerService.removejscssfile("custom_js.js", "js");
      //  this.headerService.loadScript('../assets/include/js/custom_js.js'); 
      }
      getPageData(){
      
        let params = "methodName=get_page_by_slug&slug=cookies";
        this.pagesService.getpagedata(params).subscribe( data => {
            this.cookiedata = data['data']['description'];
         },
         error => {
           alert("Server Error, Please refresh page again.");
          });  
    
      }
      getBanners(){
      this.spinner.show();
      this.errormsg = ""; 
      this.successmsg = "";
      this.homeService.getBannerList().subscribe( data => {
       // if(!localStorage.getItem("bannerloaded")){
        this.headerService.loadHomeJs();
        this.bannerlist = data['data'];
      //  }
        //  console.log(' page data ======= '+JSON.stringify(data['data']));
      if(!$("html").hasClass("fp-enabled")){
        $("html").addClass("fp-enabled");
        $(".fp-right").css({"display":"block"});
      }
        setTimeout(() => {
          window.location.href = "#MARBLE";
          this.spinner.hide();
        }, 1000);

        },
       error => {
         alert("Server Error, Please refresh page again.");
        });  

     }
}
