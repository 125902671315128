import { Injectable } from '@angular/core';
import { GlobelCodeService} from '../services/globel-code.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhilosophyDetailService {

  constructor(private http: HttpClient,private _constant:GlobelCodeService) { }
  getPhilosophyDetail(params:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
//      let params = "methodName=get_payment_data&user_id="+userid;
      return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));
  }
}
