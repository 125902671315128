import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router , ActivatedRoute} from  '@angular/router';

declare var $:any;
@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit {
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private router: Router
    ) { }
  ngOnInit() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

    $('body').addClass("darkBg");
     this.headerService.removejscssfile("custom_js.js", "js");
     this.headerService.loadScript('../assets/include/js/custom_js.js'); 
     setTimeout(() => {
      $('.mainFooter').css({"display":"none"});
    }, 500);

    }
  login(){
    this.router.navigate(['login']);
  }

}
