import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router , ActivatedRoute} from  '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { PagesService } from '../services/pages.service';

declare var $:any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
}) 
@Injectable({
  providedIn: 'root'
})
export class PagesComponent implements OnInit {
  slug :any;
  errormsg: any;
  successmsg:any;
  pagedata : any;
  checkprivacy = false;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private pagesService:PagesService
    ) { 
      //  this.pagetitle = "";
     
        this.route.params.subscribe( 
          params => {
          this.slug =params.slug;
    //      window.scrollTo(0, 0);
          this.getPageData(); 
          window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

        }
          );
      }
  ngOnInit() {
    this.pagedata = [];
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");

    setTimeout(() => {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }, 100);
  //   $(document).on('click','.faqRow .faqH',function(event) {
  //     $(this).siblings('.faqP').slideToggle();

  //     // $(this).siblings('.faqP').slideToggle().parents('.faqRow').siblings().find('.faqP').slideUp();
  //     $(this).find('.arrowSign').toggleClass('arrowUp').parents('.faqRow').siblings().find('.arrowSign').removeClass('arrowUp');
  // });

    $('body').addClass("darkBg");
     this.headerService.removejscssfile("custom_js.js", "js");
     this.headerService.loadScript('../assets/include/js/custom_js.js');
 //   this.getPageData(); 
    
    }
   getPageData(){
      
      this.spinner.show();
      this.errormsg = ""; 
      this.successmsg = "";
      let params = "methodName=get_page_by_slug&slug="+this.slug;
      this.pagesService.getpagedata(params).subscribe( data => {
          this.pagedata = data['data'];
          if(!$("html").hasClass("fp-enabled")){
            $(".fp-right").remove();
          }
          if(data['data']['top']!=''){
            this.checkprivacy = true;
          }else{
            this.checkprivacy = false;
          }
          this.spinner.hide();
       },
       error => {
         alert("Server Error, Please refresh page again.");
        });  
  
    }

  }
