import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnquiryService } from '../services/enquiry.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { HeaderService } from '../services/header.service';

declare var $:any;

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
  submitted = false;
  enquiryForm: FormGroup;
  errormsg: any;
  countrylist:any;
  faqlist:any;
  enquiryInfo:any;
  frequently_questions:any;
  successmsg:any;
  constructor(private route: ActivatedRoute,private router: Router
    ,private enquiryService:EnquiryService,
    public headerService:HeaderService,
    private enquiryform: FormBuilder
    ) { }

  ngOnInit() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    this.errormsg = "";
    this.successmsg = "";
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    $('body').addClass("darkBg");
        // single faq



      this.enquiryForm = this.enquiryform.group({
        enquiry_type: ["", [Validators.required]],
        user_title: ["", [Validators.required]],
        first_name: ["", [Validators.required]],
        last_name: ["", [Validators.required]],
        country: ["", [Validators.required]],
        //address: ["", [Validators.required]],
        about: [''],
        confirm_email: [''],
        confirm_phone: [''],
        confirm_sms: [''],
       // qissaowner: ['No', [Validators.required]],
        email: ["", [Validators.required]],
        mobileno: ["", [Validators.required]]
      });
      this.getCountry();
      this.getEnquiryInfo();
    }
    get f() { return this.enquiryForm.controls; }

    enquirySubmit(){
      this.submitted = true;
      this.errormsg = "";
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });      
      // stop here if form is invalid
      console.log('stop here if form is invalid: ',this.enquiryForm.invalid);
      if (this.enquiryForm.invalid) {
          return;
      }else {
        this.errormsg = "Please wait submitting data";
      }

      
      let params = "methodName=saveEnquiery&enquiry_type="+this.enquiryForm.value.enquiry_type+"&user_title="+this.enquiryForm.value.user_title+"&first_name="+this.enquiryForm.value.first_name+"&last_name="+this.enquiryForm.value.last_name+"&email="+this.enquiryForm.value.email+"&mobileno="+this.enquiryForm.value.mobileno+"&country="+this.enquiryForm.value.country+"&about="+this.enquiryForm.value.about+"&qissaowner="+this.enquiryForm.value.qissaowner+"&confirm_sms="+this.enquiryForm.value.confirm_sms+"&confirm_email="+this.enquiryForm.value.confirm_email+"&confirm_phone="+this.enquiryForm.value.confirm_phone+"&address="+this.enquiryForm.value.address;
      this.enquiryService.sendEnquiry(params).subscribe( data => {
       if(data['replyStatus']=="error"){
          this.successmsg = "";
          this.errormsg = data['replyMessage'];
        }
        if(data['replyStatus']=="success"){
          this.errormsg = "";
          this.successmsg = data['replyMessage'];
          this.enquiryForm = this.enquiryform.group({
            enquiry_type: [""],
            user_title: [""],
            first_name: [""],
            last_name: [""],
            country: [""],
           // address: [""],
            about: [''],
            byemail: [''],
            byphone: [''],
            bysms: [''],
           // qissaowner: ['No'],
            email: [""],
            mobileno: [""]
          });
          }
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
       },
       error => {
         alert("Server Error, Please refresh page again.");
        });  
  
    }
   clearform(){
    this.enquiryForm = this.enquiryform.group({
      enquiry_type: [""],
      first_name: [""],
      last_name: [""],
      country: [""],
      address: [""],
      about: [''],
      byemail: [''],
      byphone: [''],
      bysms: [''],
      qissaowner: ['No'],
      email: [""],
      mobileno: [""]
    });
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  } 
    getCountry(){
      this.enquiryService.getCountry().subscribe( data => {
        if(data['replyStatus']=="success"){
          this.countrylist = data['data'];
          setTimeout(() => {
            this.headerService.removejscssfile("bootstrap-select.min.js", "js");
            this.headerService.loadScript('../assets/include/js/bootstrap-select.min.js'); 
            $('.selectSty').selectpicker();
          }, 100);
        
        }
       },
       error => {
         alert("Server Error, Please refresh page again.");
        });  
  
    } 
    getEnquiryInfo(){
      this.enquiryService.getEnquiryInfo().subscribe( data => {
        if(data['replyStatus']=="success"){
          this.enquiryInfo = data['data']['informed_message'];
          this.faqlist     = data['data']['faq_list'];
          this.frequently_questions = data['data']['frequently_questions'];
        }
       },
       error => {
         alert("Server Error, Please refresh page again.");
        });  
  
    } 

  }
