import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { NgxSpinnerService } from "ngx-spinner";
import { BrandStoryService } from '../services/brand-story.service';
declare var $:any;

@Component({
  selector: 'app-brand-story',
  templateUrl: './brand-story.component.html',
  styleUrls: ['./brand-story.component.scss']
})
export class BrandStoryComponent implements OnInit {
  errormsg: any;
  successmsg:any;
  typelist = [];
  constructor(private spinner: NgxSpinnerService,public headerService:HeaderService,private brandStoryService:BrandStoryService) { }

  ngOnInit() {
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    if(!$("html").hasClass("fp-enabled")){
      $(".fp-right").css({"display":"none"});
    }
    // if(!$("body").hasClass( "scrollHidden" )){
    //   $("html, body").addClass('scrollHidden');
    // }
    if(window.location.pathname=="/brand-story"){
      $('.homeBtn').addClass('homeActiveBtn');
    }else{
      $('.homeBtn').removeClass('homeActiveBtn');
    }
    window.scrollTo({ left: 0, top: 0});
    this.headerService.loadmenujs();
    this.getBrandStoryData();
  }
   
  getBrandStoryData(){
    this.spinner.show();
    this.errormsg = "";
    this.successmsg = "";
    this.brandStoryService.getBrandStoryData().subscribe( data => {
   //   console.log("Access Request sent successfully -------- "+JSON.stringify(data['data']));
        this.typelist = data['data'];
        this.headerService.removejscssfile("custom_js.js", "js");
        this.headerService.loadScript('../assets/include/js/custom_js.js');   
        $('.mainFooter').css({"display":"none"});
        this.spinner.hide();
    // alert("shi h ");
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
}
