import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { NgxSpinnerService } from "ngx-spinner";
import { PhilosophyService } from '../services/philosophy.service';
declare var $:any;

@Component({
  selector: 'app-philosophy',
  templateUrl: './philosophy.component.html',
  styleUrls: ['./philosophy.component.css']
})
export class PhilosophyComponent implements OnInit {
  errormsg: any;
  successmsg:any;
  typelist = [];
  constructor(private spinner: NgxSpinnerService,public headerService:HeaderService,private philosophyService:PhilosophyService) { }

  ngOnInit() {
    this.errormsg = "";
    this.successmsg = "";
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");    
    window.scrollTo({ left: 0, top: 0 });
      // if(!$("body").hasClass( "scrollHidden" )){
      //   $("html, body").addClass('scrollHidden');
      // }
      this.getPhilosophyData();
  }
  
  getPhilosophyData(){
    this.spinner.show();
    this.errormsg = "";
    this.successmsg = "";
    this.philosophyService.getPhilosophyData().subscribe( data => {
   //   console.log("Access Request sent successfully -------- "+JSON.stringify(data['data']));
        this.typelist = data['data'];
        this.headerService.removejscssfile("custom_js.js", "js");
        this.headerService.loadScript('../assets/include/js/custom_js.js');   
        $('.mainFooter').css({"display":"none"});

        this.spinner.hide();
    // alert("shi h ");
     },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }  

}
