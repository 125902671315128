import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PhilosophyComponent } from './philosophy/philosophy.component';
import { PhilosophyDetailComponent } from './philosophy-detail/philosophy-detail.component';
import { BrandStoryComponent } from './brand-story/brand-story.component';
import { BrandStoryDetailComponent } from './brand-story-detail/brand-story-detail.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { PhilosophyBespokeComponent } from './philosophy-bespoke/philosophy-bespoke.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccessRequestComponent } from './access-request/access-request.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { PagesComponent } from './pages/pages.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ActivationComponent } from './activation/activation.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PhilosophyComponent,
    PhilosophyDetailComponent,
    BrandStoryComponent,
    BrandStoryDetailComponent,
    EnquiryComponent,
    PhilosophyBespokeComponent,
    LoginComponent,
    DashboardComponent,
    AccessRequestComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
    
    PagesComponent,
    
    ActivationComponent,
    
    CreatePasswordComponent
    ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFullpageModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [DeviceDetectorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
