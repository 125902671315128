import { Injectable } from '@angular/core';
import { GlobelCodeService} from '../services/globel-code.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AccessRequestService {
  constructor(private http: HttpClient,private _constant:GlobelCodeService) { }
  accessrequestpost(title:any,first_name:any,last_name:any,email:any,mobileno:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
      let params = "methodName=access_request&title="+title+"&first_name="+first_name+"&last_name="+last_name+"&email="+email+"&mobileno="+mobileno;
      return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));
  }
}
