import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { PhilosophyBespokeService } from '../services/philosophy-bespoke.service';
import { DomSanitizer} from '@angular/platform-browser';
import { Router , ActivatedRoute} from  '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
declare var $:any;

@Component({
  selector: 'app-philosophy-bespoke',
  templateUrl: './philosophy-bespoke.component.html',
  styleUrls: ['./philosophy-bespoke.component.scss']
})
export class PhilosophyBespokeComponent implements OnInit {
  errormsg: any;
  successmsg:any;
  brandstoryDetail = [];
  nextbrand = [];
  brandstoryType={
    image : "",
    title : ""
  };

  section1={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };
  section2={
    sub_title:"",
    title : "",
    type : "",
    description : "",                                                                                                                                                                                                              
    button_type : "",
    image_video : "",
    short_description : ""
    };

  section3={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  section4={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  section5={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  section6={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  section7={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  section8={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  section9={
    sub_title:"",
    title : "",
    type : "",
    description : "",
    button_type : "",
    image_video : "",
    short_description : ""
  };

  type:any;

  constructor(public headerService:HeaderService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private philosophyBespokeService:PhilosophyBespokeService) { }

  ngOnInit() {
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    if(!$("html").hasClass("fp-enabled")){
      $(".fp-right").css({"display":"none"});
    }
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

    // this.headerService.removejscssfile("custom_js.js", "js");
    // this.headerService.loadScript('../assets/include/js/custom_js.js'); 
    this.getPhilosophyBespokeDetail();
  }
  safeurl(url:any){
   // alert(url);
     url=url;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);

//     this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getPhilosophyBespokeDetail(){
    this.spinner.show();
    this.errormsg = "";
    this.successmsg = "";
    $(".menuWrap").removeClass('activeMenu');
    $(".brandmenuWrap").removeClass('activeMenu');

    let params = "methodName=get_bespoke_list";
    this.philosophyBespokeService.getPhilosopyBespokeData(params).subscribe( data => {
        this.brandstoryDetail = data['data'];
        this.section1 = data['data']['0'];
        this.section2 = data['data']['1'];
        this.section3 = data['data']['2'];
        this.section4 = data['data']['3'];
         this.section5 = data['data']['4'];
         this.section6 = data['data']['5'];
         this.section7 = data['data']['6'];
         this.section8 = data['data']['7'];
         this.section9 = data['data']['8'];
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
}
