import { Injectable } from '@angular/core';
import { GlobelCodeService} from '../services/globel-code.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient,private _constant:GlobelCodeService) { }
  getpaymentData(userid:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
      let params = "methodName=get_payment_data&user_id="+userid;
      return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));
  }
  getState(country_id:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
      let params = "methodName=get_state&country_id="+country_id;
      return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));
  }  
  getCountry(){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
      let params = "methodName=get_country";
      return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));
  }
  getMilestone(params:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
      return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));
  }
  updateBillingAddress(params:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
    return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));

  }
  updateAccount(params:any){
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
    };
    return this.http.post<any[]>(this._constant.apiUrl,params,httpOptions).pipe(map(response => response));

  }

}
