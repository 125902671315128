import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { BrandStoryDetailService } from '../services/brand-story-detail.service';

import { Router , ActivatedRoute} from  '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
declare var $:any;

@Component({
  selector: 'app-brand-story-detail',
  templateUrl: './brand-story-detail.component.html',
  styleUrls: ['./brand-story-detail.component.scss']
})

export class BrandStoryDetailComponent implements OnInit {

  errormsg: any;
  successmsg:any;
  brandstoryDetail = [];
  nextbrand = [];
  brandstoryType={
    image : "",
    title : ""
  };
  section1={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };
  section2={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
    };

  section3={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };

  section4={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };

  section5={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };

  section6={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };

  section7={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };

  section8={
    subtitle:"",
    title : "",
    description : "",
    image : "",
    short_description : ""
  };
  type:any;
  folder360 :any;
  numberofimg :any;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private brandStoryDetailService:BrandStoryDetailService
    ) { 
    //  this.route.params.subscribe( params => console.log( this.type = params.cat));
      this.route.params.subscribe( 
        params => {
        this.type =params.cat;
        console.log("folder name :  "+this.folder360);
        console.log("number of image :  "+this.numberofimg);
        console.log("this is typeee ===  "+this.type);
  //      window.scrollTo(0, 0);
        this.getBrandStoryDetail(); 
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        $('body').removeAttr("class");
        $('body').removeAttr("style");
        $('html').removeAttr("style");
        $('html').removeAttr("class");
        $('.homeBtn').removeClass('homeActiveBtn');
        if(!$("html").hasClass("fp-enabled")){
          $(".fp-right").css({"display":"none"});
        }
      }
        );
    }
  ngOnInit() {
    this.headerService.loadmenujs();
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    $('.homeBtn').removeClass('homeActiveBtn');

    this.headerService.removejscssfile("custom_js.js", "js");
    this.headerService.loadScript('../assets/include/js/custom_js.js'); 
    this.headerService.removejscssfile("360.js", "js");
    this.headerService.loadScript('../assets/include/js/360.min.js'); 
    this.getBrandStoryDetail();
  }
 otherbrands(){
  this.getBrandStoryDetail();
 }
  getBrandStoryDetail(){
    this.spinner.show();
    this.errormsg = "";
    this.successmsg = "";
    $(".menuWrap").removeClass('activeMenu');
    $(".brandmenuWrap").removeClass('activeMenu');

    let params = "methodName=get_brandstory_detail&type="+this.type;
    this.brandStoryDetailService.getBrandstoryData(params).subscribe( data => {

      console.log(' response of get_brandstory_detail' , data);
        this.brandstoryDetail = data['data']['brandStory'];
        this.brandstoryType   = data['data']['brandStory_product'];
        this.nextbrand        = data['data']['next_brand_product'];
        this.section1 = data['data']['brandStory']['0'];
        this.section2 = data['data']['brandStory']['1'];
        this.section3 = data['data']['brandStory']['2'];
        this.section4 = data['data']['brandStory']['3'];
        this.section5 = data['data']['brandStory']['4'];
        // this.section6 = data['data']['philosophy']['5'];
        // this.section7 = data['data']['philosophy']['6'];
        // this.section8 = data['data']['philosophy']['7'];
        setTimeout(() => {
          this.spinner.hide();
          
        }, 2000);
      },
     error => {
       alert("Server Error, Please refresh page again.");
      });  

  }
}
