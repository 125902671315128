import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from  '@angular/router';
declare var $:any;
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  urlstr:any;
  constructor(private route: ActivatedRoute,private router: Router) { 

  this.route.params.subscribe( params => console.log( this.urlstr = params.cat));
  }

  ngOnInit() {
    $(document).on('click','.faqRow .faqH',function(event) {
      //$(this).siblings('.faqP').slideToggle();
    });
    // faq
    $(document).on('click','.faqRow .faqH',function(event) {
      $(this).siblings('.faqP').slideToggle().parents('.faqRow').siblings().find('.faqP').slideUp();
      $(this).find('.arrowSign').toggleClass('arrowUp').parents('.faqRow').siblings().find('.arrowSign').removeClass('arrowUp');
    });
  }

  menushowhide(type:any){
  //  alert("shi h ");
  if(window.location.pathname=="/brand-story"){
    $('.homeBtn').addClass('homeActiveBtn');
  }else{
  //  $('.homeBtn').removeClass('homeActiveBtn');
  }

  if(type=="rightMenu"){

    if(!$(".brandmenuWrap").hasClass('activeMenu')){
      if(window.location.pathname=="/dashboard"){
      if($("body").hasClass('whiteBg')){
        $('body').removeClass('whiteBg');
      }else{
        $('body').addClass('whiteBg');
      }
      // $('body').addClass('whiteBg');
      // $('body').toggleClass('whiteBg');
    }

  }
  if(!$(".menuWrap").hasClass('activeMenu')){
    $('.menuBtn1').removeClass('activeMenuBtn');
  }
  $('.homeBtn').removeClass('homeActiveBtn');
  $(".brandmenuWrap").removeClass('activeMenu');
    var menutop = $(document).scrollTop();
    $('.menuBtn1').toggleClass('activeMenuBtn');
    $(".menuWrap").css('top', menutop);
    $(".menuWrap").toggleClass('activeMenu');

    if($(".menuWrap").hasClass('activeMenu')){
      $("html, body").addClass('scrollHidden');
    }else{
      $("html, body").removeClass('scrollHidden');
    }
  
  }

  if(type=="leftMenu"){
    if($(".brandmenuWrap").hasClass('activeMenu')){
      $("html, body").removeClass('scrollHidden');
    }else{
      $("html, body").addClass('scrollHidden');
    }
    if(!$(".menuWrap").hasClass('activeMenu')){
      if(window.location.pathname=="/dashboard"){
        if($("body").hasClass('whiteBg')){
          $('body').removeClass('whiteBg');
        }else{
          $('body').addClass('whiteBg');
        }
        $('.menuBtn1').removeClass('activeMenuBtn');
   
        // $('body').addClass('whiteBg');
        // $('body').toggleClass('whiteBg');
      }
  
      if(window.location.pathname=="/brand-story"){
      $('.homeBtn').addClass('homeActiveBtn');
      var menutop = $(document).scrollTop();
     // $('.menuBtn1').toggleClass('activeMenuBtn');
      $(".menuWrap").css('top', menutop);
      $(".menuWrap").toggleClass('activeMenu');
    }else{
   //   alert('d');
          var menutop = $(document).scrollTop();
          $('.homeBtn').toggleClass('homeActiveBtn');
          $(".brandmenuWrap").css('top', menutop);
          $(".menuWrap").removeClass('activeMenu');
          $(".brandmenuWrap").toggleClass('activeMenu');

        }
     //   $("html, body").toggleClass('scrollHidden');
      }
  }


    // if($("body").hasClass( "scrollHidden" )){
    //   $("html, body").removeClass('scrollHidden');
    // }else{
    //   $("html, body").addClass('scrollHidden');
    // }
 }
}
