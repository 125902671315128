import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobelCodeService {

  readonly apiUrl: string = 'https://qissa.com/admin-app/api';
  constructor() {}

}
