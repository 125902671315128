import { Injectable,OnInit } from '@angular/core';

declare var document: any;
declare var $:any;

@Injectable({
  providedIn: 'root'
})


export class HeaderService  implements OnInit {
  private scripts: any = [];

  constructor() {
   } 
   ngOnInit(){

   // this.loadmenujs();
   }
 header_fixed() {
			var winScrollTop = $(document).scrollTop();
			if (winScrollTop > 0) {
				$('.mainHeader').addClass('fixedHeader');
			}
			else{
				$('.mainHeader').removeClass('fixedHeader');
			}
		}
   loadmenujs(){
    this.removejscssfile("custom_js.js", "js");
    this.loadScript('../assets/include/js/custom_js.js'); 
   }
   removejscssfile(filename:any, filetype:any){
    $('.mainFooter').css({"display":"block"});
    var targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" //determine element type to create nodelist from
    var targetattr=(filetype=="js")? "src" : (filetype=="css")? "href" : "none" //determine corresponding attribute to test for
    var allsuspects=document.getElementsByTagName(targetelement)
    for (var i=allsuspects.length; i>=0; i--){ //search backwards within nodelist for matching elements to remove
    if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i].getAttribute(targetattr).indexOf(filename)!=-1)
        allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
    }
}
 loadScript(url: string) {
  const body = <HTMLDivElement> document.body;
  const script = document.createElement('script');
  script.innerHTML = '';
  script.src = url;
  script.async = true;
  script.defer = true;

//    if(this.scriptAdded == false) {
    body.appendChild(script);
//  }
  // else{
  //   body.replaceChild(script);
  // }
} 
loadHomeJs(){
  setTimeout(() => {
    this.removejscssfile('home.js','js');
    this.loadScript('../assets/include/js/home.js');
      
  }, 1000);
}
}
