import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { AccessRequestService } from '../services/access-request.service';
declare var $:any;

@Component({
  selector: 'app-access-request',
  templateUrl: './access-request.component.html',
  styleUrls: ['./access-request.component.scss']
})
export class AccessRequestComponent implements OnInit {
  requestAccessForm: FormGroup;
  submitted = false;
  errormsg: any;
  btnlable = "REQUEST ACCESS";
  successmsg:any;
  requestsent=false;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private accessrequest: FormBuilder,
    private accessRequestService:AccessRequestService,
    private router: Router
    ) { }
  ngOnInit() {
    this.requestsent = false;
    this.errormsg = "";
    
    this.successmsg = "";
    setTimeout(() => {
      this.headerService.removejscssfile("bootstrap-select.min.js", "js");
      this.headerService.loadScript('../assets/include/js/bootstrap-select.min.js'); 
      $('.selectSty').selectpicker();
    }, 100);  
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

    $('body').addClass("darkBg");
     this.headerService.removejscssfile("custom_js.js", "js");
     this.headerService.loadScript('../assets/include/js/custom_js.js'); 
     setTimeout(() => {
      $('.mainFooter').css({"display":"none"});
    }, 500);

     this.requestAccessForm = this.accessrequest.group({
      title: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileno: ['', [Validators.required, Validators.minLength(10)]]
    });
     
    }
    get f() { return this.requestAccessForm.controls; }

    requestToAccess(){
      this.submitted = true;

      // stop here if form is invalid
      if (this.requestAccessForm.invalid) {
          return;
      }
      this.errormsg = "";
      this.btnlable = "PLEASE WAIT..";
      this.accessRequestService.accessrequestpost(this.requestAccessForm.value.title,this.requestAccessForm.value.first_name,this.requestAccessForm.value.last_name,this.requestAccessForm.value.email,this.requestAccessForm.value.mobileno).subscribe( logindata => {
       // console.log("Access Request sent successfully -------- "+JSON.stringify(logindata['data']));
       if(logindata['replyStatus']=="error"){
          this.successmsg = "";
          this.errormsg = logindata['replyMessage'];
        }
        if(logindata['replyStatus']=="success"){
          this.errormsg = "";
          this.requestsent = true;

          this.successmsg = "";
          // setTimeout(() => {
          //   this.router.navigate(['login']);
          // }, 3000);
        }
        this.btnlable = "REQUEST ACCESS";
       },
       error => {
         alert("Server Error, Please refresh page again.");
        });  

    }
  login(){
    this.router.navigate(['login']);
  }


}
