import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { Router , ActivatedRoute} from  '@angular/router';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';

declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  btnlable = "LOGIN";
  submitted = false;
  errormsg: any;
  successmsg:any;
  constructor(
    public headerService:HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService :LoginService,
    private login: FormBuilder
    ) { }
  ngOnInit() {
    this.errormsg = "";
    this.successmsg = "";
    if(localStorage.getItem('userdata')){
      this.router.navigate(['dashboard']);
    }
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    $('body').removeAttr("class");
    $('body').removeAttr("style");
    $('html').removeAttr("style");
    $('html').removeAttr("class");
    $('body').addClass("darkBg");

     this.headerService.removejscssfile("custom_js.js", "js");
     this.headerService.loadScript('../assets/include/js/custom_js.js'); 
    setTimeout(() => {
      $('.mainFooter').css({"display":"none"});
    }, 500);
     this.loginForm = this.login.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
  });

    }
    get f() { return this.loginForm.controls; }
    onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }
      this.errormsg = "";
      this.btnlable = "Please Wait..";
      this.loginService.loginpost(this.loginForm.value.email,this.loginForm.value.password).subscribe( logindata => {
       // console.log("login successfully -------- "+JSON.stringify(logindata['data']));
       if(logindata['replyStatus']=="error"){
          this.successmsg = "";
          this.errormsg = logindata['replyMessage'];
        }
        if(logindata['replyStatus']=="success"){
          localStorage.setItem('userdata',JSON.stringify(logindata['data']));
          this.errormsg = "";
          this.successmsg = logindata['replyMessage'];
          setTimeout(() => {
            this.router.navigate(['dashboard']);
          }, 1000);
        }
        this.btnlable = "LOGIN";

      },
       error => {
         alert("Server Error, Please refresh page again.");
        });  
  }
    dashboard(){
    this.router.navigate(['dashboard']);
  }
}
